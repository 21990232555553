import {useCallback, useEffect, useState, useRef} from "react";
import {useKey, useLocalStorage} from "react-use";
import {ethers} from "ethers";
import {useNavigate} from "react-router-dom";
import {useId} from "./hooks";
import {contractSubNouns, contractNouns,contractNounsDescriptor, contractNounsAuction, contractSubNounsMint, provider } from "./contracts";
import { initOnboard, initNotify } from "./wallets";

const BACKGROUNDS = ['d5d7e1', 'e1d7d5'];

export default function App() {
  const navigate = useNavigate();
  const [localWallet, setLocalWallet, removeLocalWallet] = useLocalStorage('wallet');
  const [nounsTotalSupply, setNounsTotalSupply] = useState(-1);
  const [subNounsTotalSupply, setSubNounsTotalSupply] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [nounImage, setNounImage] = useState('');
  const [nounOwner, setNounOwner] = useState('');
  const [subNounOwner, setSubNounOwner] = useState('');
  const [background, setBackground] = useState(BACKGROUNDS[0]);
  const [nounAuctionEnd, setNounAuctionEnd] = useState(0);
  const [price, setPrice] = useState(null);
  const [address, setAddress] = useState(undefined);
  const [ens, setEns] = useState(undefined);
  const [network, setNetwork] = useState(null);
  const [wallet, setWallet] = useState({});
  const [onboard, setOnboard] = useState(null);
  const [notify, setNotify] = useState(null);
  const cache = useRef({});
  const id = useId();
  const onNext = useCallback(() => { if (loading || id === subNounsTotalSupply) return; navigate('?id=' + (id + 1)); }, [navigate, id, subNounsTotalSupply, loading]);
  const onPrev = useCallback(() => { if (loading || id === 0) return; navigate('?id=' + (id - 1)); }, [navigate, id, loading]);
  useKey('ArrowRight', onNext, {}, [id]);
  useKey('ArrowLeft', onPrev, {}, [id]);
  useEffect(() => {
    const onboard = initOnboard({
      address: setAddress,
      ens: setEns,
      network: setNetwork,
      wallet: wallet => {
        if (wallet.provider) {
          setWallet(wallet);
          setLocalWallet(wallet.name);
        } else {
          setWallet({})
        }
      }
    })
    setOnboard(onboard);
    setNotify(initNotify());
  }, []);
  useEffect(() => {
    if (localWallet && onboard) onboard.walletSelect(localWallet);
  }, [localWallet, onboard]);
  useEffect(() => {
    contractNouns.totalSupply().then(value => setNounsTotalSupply(Number(value)));
    contractSubNouns.totalSupply().then(value => setSubNounsTotalSupply(Number(value)));
    contractSubNouns.price().then(value => setPrice(value));
  }, []);
  useEffect(() => {
    if (subNounsTotalSupply !== -1 && (id === -1 || id > subNounsTotalSupply)) navigate('?id=' + subNounsTotalSupply);
  }, [navigate, id, subNounsTotalSupply]);
  useEffect(() => {
    (async () => {
      try {
        if (id === nounsTotalSupply - 1) setNounAuctionEnd(Number((await contractNounsAuction.auction())[3]));
      } catch(e) {}
    })();
  }, [id, nounsTotalSupply]);
  useEffect(() => {
    (async () => {
      if (id === -1) return;
      if (id in cache.current) {
        setNounOwner(cache.current[id].owner);
        setSubNounOwner(cache.current[id].subOwner);
        setBackground(cache.current[id].background);
        setNounImage(cache.current[id].image);
        return;
      }
      setLoading(true);
      cache.current[id] = {};
      contractNouns.ownerOf(id).then(owner => {
        setNounOwner(owner);
        cache.current[id].owner = owner;
      }).catch(console.log);
      contractSubNouns.ownerOf(id).then(owner => {
        setSubNounOwner(owner);
        cache.current[id].subOwner = owner;
      }).catch(console.log);
      try {
        const seed = await contractNouns.seeds(id);
        //setBackground(BACKGROUNDS[seed[0]]);
        //cache.current[id].background = BACKGROUNDS[seed[0]];
        let svg = await contractNounsDescriptor.generateSVGImage([seed[0], seed[1], seed[2], seed[3], seed[4]]);
        const colors = ['#000000', '#1f1d29', '#3a085b', '#410d66', '#2b2834', '#1e3445', '#4d271b', '#343235', '#552e05', '#0b5027'];
        svg = atob(svg).substring(166);
        svg = '<svg width="340" height="420" viewBox="0 0 340 420" fill="none" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges"><rect x="0" y="0" width="340" height="420" fill="#E02826"/><rect x="10" y="10" width="320" height="400" fill="' + colors[id % 10] + '"/><rect x="60" y="30" width="50" height="10" fill="white"/><rect x="80" y="40" width="10" height="60" fill="white"/><rect x="130" y="30" width="10" height="70" fill="white"/><rect x="170" y="30" width="10" height="30" fill="white"/><rect x="210" y="30" width="10" height="30" fill="white"/><rect x="190" y="80" width="10" height="20" fill="white"/><rect x="160" y="60" width="10" height="40" fill="white"/><rect x="220" y="60" width="10" height="40" fill="white"/><rect x="180" y="60" width="10" height="20" fill="white"/><rect x="200" y="60" width="10" height="20" fill="white"/><rect x="240" y="30" width="10" height="70" fill="white"/><rect x="250" y="30" width="30" height="10" fill="white"/><rect x="250" y="60" width="30" height="10" fill="white"/><rect x="250" y="90" width="30" height="10" fill="white"/><svg x="10" y="90" width="320" height="320" viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges">' + svg + '</svg>';
        /*string memory svgTag = 'fill="none" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges">';
        image = abi.encodePacked(
            '<svg width="340" height="420" viewBox="0 0 340 420" ',
            svgTag,
            parts, '<svg x="10" y="90" width="320" height="320" viewBox="0 0 320 320" ', svgTag, image, '</svg>');*/



        const image = 'data:image/svg+xml;base64,' + btoa(svg);
        setNounImage(image);
        cache.current[id].image = image;
        setLoading(false);
      } catch(e) { console.log(e); }
    })();
  }, [id]);
  const onConnect = useCallback(() => {
    (async () => {
      if (await onboard.walletSelect()) {
        await onboard.walletCheck();
      }
    })();
  }, [onboard]);
  const onDisconnect = useCallback(() => {
    onboard.walletReset();
    removeLocalWallet();
  }, [onboard]);
  const onMint = useCallback(() => {
    (async () => {
      try {
        if (!await onboard.walletCheck()) return;
        const {hash} = await contractSubNounsMint(wallet.provider)({value: price});
        const {emitter} = notify.hash(hash);
        emitter.on('txPool', tx => {
          return {onclick: () => window.open(`https://etherscan.io/tx/${tx.hash}`)};
        });
        emitter.on('txConfirmed', tx => {
          cache.current[id].subNounOwner = tx.from;
          contractSubNouns.totalSupply().then(value => {
            setSubNounsTotalSupply(Number(value));
          });
        });
      } catch(e) { console.log(e); }
    })();
  }, [wallet, price, id]);
  return (<>
    <div className="w-full" style={{backgroundColor: '#' + background}}>
      <div className="w-full max-w-screen-lg mx-auto pt-6 px-8 lg:flex lg:justify-between">
        <a href="/"><img src="/images/logo.svg" alt="Time Is A Noun" className={"h-8"} /></a>
        {address && <button onClick={onDisconnect} className="w-full lg:w-auto mt-4 lg:mt-0 px-4 py-1 bg-gray-100 hover:bg-white cursor-pointer rounded-lg text-gl font-bold leading-8 text-center select-none">Disconnect</button>}
      </div>
      <div className="w-full max-w-screen-lg mx-auto grid lg:grid-cols-2 lg:gap-8 pt-16 px-8">
        <div>
          {loading ? <img src="images/loading.gif" alt="Loading..." width="100%"/> : <img src={nounImage} width="100%" alt="Time Is A Noun"/>}
        </div>
        {id !== -1 && nounsTotalSupply !== -1 && subNounsTotalSupply !== -1 && price !== null && <div className="pb-4">
          <div className="flex">
            <button onClick={onPrev} className={`${id === 0 ? 'opacity-50' : 'hover:bg-white cursor-pointer'} w-8 h-8 bg-gray-100 rounded-full text-gl font-bold leading-8 text-center select-none`}>←</button>
            <button onClick={onNext} className={`${id === subNounsTotalSupply ? 'opacity-50' : 'hover:bg-white cursor-pointer'} ml-2 w-8 h-8 bg-gray-100 rounded-full text-gl font-bold leading-8 text-center select-none`}>→</button>
          </div>
          <h1 className="text-6xl font-bold text-gray-900 py-8 lg:whitespace-nowrap" style={{fontFamily: 'Londrina Solid'}}>Time Is A Noun {id}</h1>
          <Info {...{id, nounsTotalSupply, subNounsTotalSupply, nounAuctionEnd, price, onConnect, onMint, address, ens, nounOwner, subNounOwner, provider}}/>
          <div className="mt-4 flex space-x-4">
            {id !== subNounsTotalSupply && <a href={'https://opensea.io/assets/' + process.env.REACT_APP_SUBNOUNS_CONTRACT + '/' + id} target="_blank" rel="noreferrer" className="px-4 py-1 bg-gray-100 hover:bg-white cursor-pointer rounded-lg text-gl font-bold leading-8 text-center select-none">OpenSea</a>}
            {id !== nounsTotalSupply - 1 && <a href={'https://nouns.wtf/noun/' + id} target="_blank" rel="noreferrer" className="px-4 py-1 bg-gray-100 hover:bg-white cursor-pointer rounded-lg text-gl font-bold leading-8 text-center select-none">Noun {id}</a>}
          </div>
        </div>}
      </div>
    </div>
    <div className="w-full max-w-screen-lg mx-auto grid lg:grid-cols-2 gap-8 pt-16 px-8">
      <div>
        <h1 className="text-8xl text-gray-900 lg:whitespace-pre uppercase" style={{fontFamily: 'Londrina Solid'}}>{"A time\nnoun for\neach Noun"}</h1>
      </div>
      <div>
        <img src="/images/example.svg" alt="Example Time Is A Noun" width="100%" />
      </div>
    </div>
    <div className="w-full max-w-screen-lg mx-auto pt-16 px-8">
      <div className="w-full max-w-screen-lg">
        <h1 className="text-6xl font-bold text-gray-900 py-8" style={{fontFamily: 'Londrina Solid'}}>WTF?</h1>
        <p className="text-xl">"Time Is A Noun" is a completely on-chain derivative of Nouns. Part of <a href="https://twitter.com/SubNouns" target="_blank" rel="noreferrer">SubNouns project</a>. Inspired by <a href="https://foundation.app/@4156/foundation/12141" target="_blank" rel="noreferrer">TIME</a> by <a href="https://twitter.com/punk4156" target="_blank" rel="noreferrer">@punk4156</a>. For every 10th "Time Is A Noun" 100% of the mint price goes to the Nouns DAO. The first 41 "Time Is A Noun" were airdropped to the holders of the first 41 Nouns.
          Mint "Time Is A Noun" to support my work. Two more interesting derivatives are in development.</p>
      </div>
      <div className="w-full max-w-screen-lg py-16 flex space-x-8 justify-center">
        <a href="https://subnouns.wtf">SubNouns</a> <a href="https://opensea.io/collection/time-is-a-noun">OpenSea</a> <a href="https://twitter.com/SubNouns">Twitter</a> <a href={'https://etherscan.io/address/' + process.env.REACT_APP_SUBNOUNS_CONTRACT}>Etherscan</a> <a href="https://github.com/SubNouns">GitHub</a>
      </div>
    </div>
  </>);
}

function Info({id, nounsTotalSupply, subNounsTotalSupply, nounAuctionEnd, price, onConnect, onMint, address, ens, nounOwner, subNounOwner, provider}) {
  const [subNounOwnerEns, setSubNounOwnerEns] = useState('');
  const cacheEns = useRef({});
  const subNounOwnerRef = useRef();
  useEffect(() => {
    if (subNounOwner in cacheEns.current) {
      setSubNounOwnerEns(cacheEns.current[subNounOwner]);
      return;
    }
    setSubNounOwnerEns('');
    if (subNounOwner) {
      subNounOwnerRef.current = subNounOwner;
      provider.lookupAddress(subNounOwner).then(ens => {
        if (subNounOwner !== subNounOwnerRef.current) return;
        cacheEns.current[subNounOwner] = ens;
        setSubNounOwnerEns(ens);
      }).catch(console.log);
    }
  }, [subNounOwner]);
  if (id < 41) return (<>
    <div className="font-semibold text-gray-500 mt-2">Airdroped to the holder of the Noun {id}</div>
    <div className="font-semibold text-gray-500 mt-2">Held by <a href={"https://etherscan.io/address/" + subNounOwner} target="_blank" rel="noreferrer">{subNounOwner && (address && subNounOwner.toLowerCase() === address.toLowerCase() ? 'you!' : subNounOwner.slice(0, 5) + '...' + subNounOwner.slice(-4))}</a></div>
  </>);
  if (id === nounsTotalSupply - 1) {
    if (nounAuctionEnd !== 0) return (<>
      <div className="font-semibold text-gray-500 mt-2">Auction ends on {new Date(nounAuctionEnd * 1000).toLocaleDateString('en', {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'})}</div>
      <a href={'https://nouns.wtf/noun/' + id} target="_blank" rel="noreferrer" className="block mt-4 p-2 bg-gray-100 hover:bg-white cursor-pointer rounded-lg text-gl font-bold leading-8 text-center select-none">Open Nouns Auction</a>
    </>);
    return null;
  }
  if (id < subNounsTotalSupply) return (<div className="font-semibold text-gray-500 mt-2">Held by <a href={"https://etherscan.io/address/" + subNounOwner} target="_blank" rel="noreferrer">{subNounOwner && (address && subNounOwner.toLowerCase() === address.toLowerCase() ? 'you!' : subNounOwnerEns ? subNounOwnerEns : (subNounOwner.slice(0, 5) + '...' + subNounOwner.slice(-4)))}</a></div>);
  return (<>
    <div className="font-semibold text-gray-500 mt-2">Mint Price</div>
    <div className="text-3xl font-semibold mt-2">Ξ {ethers.utils.formatEther(price)}</div>
    {!address && <button onClick={onConnect} className="w-full mt-4 p-2 bg-gray-100 hover:bg-white cursor-pointer rounded-lg text-gl font-bold leading-8 text-center select-none">Connect Wallet</button>}
    {address && <button onClick={onMint} className="w-full mt-4 p-2 text-white bg-gray-900 hover:bg-gray-800 cursor-pointer rounded-lg text-gl font-bold leading-8 text-center select-none">Mint to {ens && ens.name ? ens.name : address.slice(0, 5) + '...' + address.slice(-4)}</button>}
    {id % 10 === 0 && <div className="mt-2 flex items-center space-x-2">
      <span className="font-semibold text-gray-500">100% goes to <a href="https://etherscan.io/address/0x0BC3807Ec262cB779b38D65b38158acC3bfedE10" target="_blank" rel="noreferrer">the Nouns DAO</a> by contract</span>
      <button className="bg-gray-500 text-white text-xs rounded-full w-4 h-4" title="For every 10th Noun 100% of the mint price goes to the Nouns DAO.">?</button>
    </div>}
  </>);
}